<template>
  <div class="main">
    <!-- 机构认证成功 -->
    <ul class="big" v-if="$route.query.flag">
      <li class="iamge"><img src="../assets/examine/success.png" alt="" /></li>
      <li class="title1">提交成功</li>
      <li class="title2">
        厂牌/机构认证申请提交成功，若审核通过，将有工作人员与您联系～
      </li>
      <li class="button">
        <el-button @click="$router.replace({path:'/',query:{flag:true}})">回到首页</el-button>
      </li>
    </ul>
    <!-- 机构认证审核中 -->
    <ul class="big" v-if="userCompanyVostate==0&&!$route.query.flag" >
      <li><img src="../assets/examine/examine.png" alt="" /></li>
      <li class="title1">
        厂牌/机构认证申请审核中
      </li>
      <li class="title2">
        如审核通过，将有工作人员与您取得联系，请保持手机畅通～
      </li>
      <li class="button">
        <el-button  @click="$router.replace({path:'/',query:{flag:true}})">回到首页</el-button>
      </li>
    </ul>
    <!-- 机构认证失败 -->
    <ul class="big"  v-if="(userCompanyVostate==2 || userCompanyVostate == 3)&&!$route.query.flag" >
      <li><img src="../assets/examine/fail.png" alt="" /></li>
      <li class="title1">
        厂牌/机构认证申请失败
      </li>
      <li class="title3">
        <p>很抱歉，您的企业认证申请未通过</p>
        <p class="reason">原因: {{remark}}</p>
        <p>如有疑问，请联系<a href="mailto:artist@hikoon.com">artist@hikoon.com</a></p>
      </li>
      <li class="button">
            <el-button class="gohome" @click="$router.replace({path:'/',query:{flag:true}})" >返回首页</el-button>
            <el-button @click="$router.replace('/certification/certificationFrom')">继续认证</el-button>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
    created(){
        this.$store.state.release=true;
         this.$post('/api/user/userMsg').then(res=>{
          this.userCompanyVostate=res.data.userCompanyVo.status //状态
          this.remark=res.data.userCompanyVo.remark ||''//失败原因
          this.$store.commit('hiddenBut',res.data)
           this.$store.commit('UMSG',res.data)
          setTimeout(()=>{
            if(this.userCompanyVostate === 1 && this.$route.query.flag !== 'true'){
              this.$router.push('/create')
            }
          },100)
     })
    },

    computed:{
        // userCompanyVostate(){
        //     return this.$store.state.user.userCompanyVo.userCompanyVo
        // }
    },
  beforeRouteUpdate(to,from,next){
    // 复用同一个组件页面状态不改变用来更新状态
    this.$store.state.release=true;
    this.$post('/api/user/userMsg').then(res=>{
      this.userCompanyVostate=res.data.userCompanyVo.status //状态
      this.remark=res.data.userCompanyVo.remark ||''//失败原因
      this.$store.commit('hiddenBut',res.data)
      this.$store.commit('UMSG',res.data)
      setTimeout(()=>{
        if(this.userCompanyVostate === 1 && this.$route.query.flag !== 'true'){
          this.$router.push('/create')
        }
      },100)
    })
    next()
  },
    data() {
        return {
        userCompanyVostate:0,
        remark:''
        }
    },

}
</script>

<style scoped lang="scss">
* {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}

.main {
  overflow: hidden;
  margin: 0px auto;
  margin-top: 30px;
  width: 1100px;
  height: 700px;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  //  align-items: center;
  .big {
    margin-top: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title1 {
      margin-top: 24px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 28px;
    }
    .title2 {
      margin-top: 12px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666660;
      line-height: 20px;
    }
    .title3 {
      margin-top: 12px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666660;
      line-height: 20px;
      width: 233px;
      .reason{
          color:red;

      }
      a{
         color: #666660;
          text-decoration:none;
      }
    }
    .button {
      margin-top: 24px;
      .el-button {
        background-color: #ff004d;
        color: #ffffff;
        width: 176px;
        height: 40px;
      }
      .gohome{
          background: #F4F4F4;
          color:#FF004D;
          margin-right: 30px;
      }
    }
  }
}
</style>
